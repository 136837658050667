import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import BannerActions from "../../components/banners/bannerActions"
import { Section, Container } from "../../components/layoutComponents"
import SpecificProject from "../../components/gallery/specificProject"
import Banner from "../../images/banner.png"
import Title from "../../components/title"
import FeaturedTestimonial from "../../components/testimonials/featuredTestimonial"

import Project1 from "../../images/projects/industrial/30-kva-transformer-distribution.jpg"
import Project2 from "../../images/projects/industrial/busy-week-messy-desk.jpg"
import Project3 from "../../images/projects/industrial/industrial-troubleshooting-of-lighting-wiring-systems.jpg"
import Project4 from "../../images/projects/industrial/transformer-for-cnc-machine.jpg"
import Project5 from "../../images/projects/industrial/pvc-underground-conduits-for-electrical-outlets.jpg"
import Project6 from "../../images/projects/industrial/pvc-underground-conduits.jpg"
import Project7 from "../../images/projects/industrial/event-trailer-for-field-evaluation.jpg"

export default function IndustrialProjects() {
  return (
    <Layout>
      <SEO title="Industrial Electrical Projects" />
      <BannerActions img={Banner} />
      <Section>
        <Container className="spacing">
          <Title title="industrial electrical project gallery" />
          <SpecificProject
            img={Project1}
            altText="industrial electrical project ontario"
            description="30kVA transformer distribution"
          />
          <SpecificProject
            img={Project2}
            altText="industrial electrical project ontario"
            description="busy week, messy desk"
          />
          <SpecificProject
            img={Project3}
            altText="industrial electrical project ontario"
            description="industrial troubleshooting of lighting wiring systems"
          />
          <SpecificProject
            img={Project4}
            altText="industrial electrical project ontario"
            description="3Ph 600V ~ 3Ph 120/208V Transformer we installed for a CNC machine"
          />
          <SpecificProject
            img={Project5}
            altText="industrial electrical project ontario"
            description="PVC underground conduits for electrical outlets"
          />
          <SpecificProject
            img={Project6}
            altText="industrial electrical project ontario"
            description="PVC underground conduits"
          />
          <SpecificProject
            img={Project7}
            altText="industrial electrical project ontario"
            description="event trailer for ESAFE/CSA field evaluation"
          />
        </Container>
      </Section>
      <FeaturedTestimonial
        testimonial="I highly recommend James as he is incredibly easy to work with. James is quick to respond through both emails and phone calls, and all deadlines set between myself and James were met in a timely matter which allowed the project to run smoothly and on time. James and the Royco Electric team clearly care about their customers and vendors and I was treated with respect through all my dealings with James. I look forward to continuing working with James!"
        company="Aaron Tonner Web Solutions"
        name="AARON TONNER"
      />
    </Layout>
  )
}
